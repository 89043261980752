import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useState, useEffect } from "react";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";



export const NavBar = () => {
  // this is the state for the active link
  // useState is a hook that allows you to have state variables in functional components
  // in this case, we are setting the initial state of activeLink to 'home'
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  

  const onUpdateActiveLink = (link) => {
    // make sure to re-render the navbar after we update the active link

    // this is how you do

    setActiveLink(link);
  };
  

  return (
   
    <Navbar className={scrolled ? "scrolled" : ""} expand="lg">
      <Container>
        <a href="/" style={{ textDecoration: "none" }}>
          <span className="duccio ">Duccio Rocca</span>
        </a>

        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
          
            <Nav.Link
              href="#home"  
              className={
                activeLink === "home" ? "active navbar-link" : "navbar-link"
              }
              onClick={() => onUpdateActiveLink("home")}
            >
              Home
            </Nav.Link>
            
            
            <Nav.Link
              href="#skills"
              className={
                activeLink === "skills" ? "active navbar-link" : "navbar-link"
              }
              onClick={() => onUpdateActiveLink("skills")}>
              
              Skills
            </Nav.Link>
            


            
            <Nav.Link
              
              href="#projects"
              className={
                activeLink === "projects"
                  ? "active navbar-link"
                  : "navbar-link "
              }
              onClick={() => onUpdateActiveLink("projects")}
            >
              Projects
            </Nav.Link>
            
          </Nav>
          <span className="navbar-text">
            <div className="social-icon">
              <a
                href="https://www.linkedin.com/in/duccio-rocca/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={navIcon1} alt="LinkedIn Logo" />
              </a>
              <a
                href="https://github.com/RINO-GAELICO"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={navIcon2} alt="GitHub Logo" />
              </a>

              <button className="vvd" onClick={() => {
                const contactSection = document.getElementById('connect');
                contactSection.scrollIntoView({ behavior: 'smooth' });
              }}>
                <span>Let's Connect</span>{" "}
              </button>
            </div>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    
  );
};
